





































































































































































































import {
  useFetch,
  ref,
  useRoute,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';
import axios from 'axios';
import type { MetaInfo } from 'vue-meta';
import LazyHydrate from 'vue-lazy-hydration';
import Icon from '../../../almarwan/components/Icon.vue';
import SocialLabel from '../../../almarwan/components/SocialLabel.vue';
import { useUiHelpers, useUiNotification } from '../../../composables';
import SkeletonLoader from '../../../components/SkeletonLoader/index.vue';
import Gallery from '../../../almarwan/views/blog/components/Gallery.vue';
import TopArticles from '../../../almarwan/views/blog/components/TopArticles.vue';
import SubscribeWidget from '../../../almarwan/components/SubscribeWidget.vue';
import LatestNews from '../../../almarwan/views/blog/components/LatestNews.vue';
import CustomerStories from '../../../almarwan/views/blog/components/CustomerStories.vue';
import CustomBreadcrumbs from '../../../components/custom/CBreadcrumbs.vue';
import { Breadcrumb } from '../../../modules/catalog/types';

export default defineComponent({
  name: 'News-Page',
  components: {
    CustomBreadcrumbs,
    SocialLabel,
    Icon,
    Gallery,
    TopArticles,
    SubscribeWidget,
    LatestNews,
    CustomerStories,
    SkeletonLoader,
    LazyHydrate,
  },
  setup() {
    const baseUrlCms = 'https://cms.almarwan.com/';
    const { getFullUrl } = useUiHelpers();
    const { send: sendNotification } = useUiNotification();
    const route = useRoute();
    const {
      app: { i18n },
    } = useContext();
    const articleData = ref(null);
    const paginationVals = ref([]);
    const imageGallery = ref<string[]>([]);
    const imageGalleryAlt = ref<string[]>([]);
    const blogLoading = ref(false);

    let aliasTxt = '/' + route.value.params.title;

    const getLangCode = () => (i18n.locale === 'ar' ? '&langcode=ar' : '');

    const options = {
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json',
      },
    };

    useFetch(async () => {
      if (route?.value.params?.id) {
        blogLoading.value = true;

        const articleResponse = await axios.get(
          `${baseUrlCms}api/blog/articles?_format=json&alias=${aliasTxt}${getLangCode()}`,
          options
        );
        if (articleResponse.data) {
          articleData.value = articleResponse.data.rows[0];
          paginationVals.value = articleResponse.data.pager;
          if (articleData.value && articleData.value) {
            imageGallery.value.push(articleData.value.field_image);
            imageGalleryAlt.value.push(articleData.value.title);
            const htmlString = articleData.value.body;
            const imgSrcRegex = /<img[^>]+src="([^"]+)"/g;
            const imgAltRegex = /<img[^>]+alt="([^"]+)"/g;
            let match;
            while ((match = imgSrcRegex.exec(htmlString)) !== null) {
              imageGallery.value.push(match[1]);
            }
            while ((match = imgAltRegex.exec(htmlString)) !== null) {
              imageGalleryAlt.value.push(match[1]);
            }
          }
        }
        blogLoading.value = false;
      }
    });

    return {
      articleData,
      paginationVals,
      imageGallery,
      imageGalleryAlt,
      blogLoading,
      sendNotification,
      getFullUrl,
    };
  },
  computed: {
    fieldTags(): string[] {
      return this.articleData?.field_tags?.split(',') || [];
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          link: '/news',
          text: 'News',
        },
        {
          link: `${process.env.BASE_URL}news/${
            this.articleData?.nid
          }/${this.articleData?.view_node.substr(1)}`,
          text:
            this.articleData?.field_meta_title || this.articleData?.title || '',
        },
      ];
    },
  },
  methods: {
    stripHtmlAndNewlines(htmlContent) {
      let regex = /(<([^>]+)>)/gi;
      var txt = htmlContent.replace(regex, '');
      txt = txt.replace(/\n|\r/g, '');
      return txt;
    },
    dateFormater(dateStr) {
      const dateObject = new Date(dateStr);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate.toString();
    },
    getImgSrc(src) {
      return src?.startsWith('/') ? src.slice(1) : src;
    },
    async copyUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(window.location.href);
        this.sendNotification({
          id: Symbol('user_updated'),
          message: `Link is copied to the clipboad`,
          type: 'success',
          icon: 'check',
          persist: false,
        });
      } catch (err) {
        console.error('Failed to copy URL: ', err);
      }
    },
  },
  head(): MetaInfo {
    if (this.articleData) {
      const articleTitle = this.articleData?.title;
      const metaDescription = this.articleData?.field_meta_description;
      const articleURL = `${process.env.BASE_URL}news/${
        this.articleData?.nid
      }/${this.articleData?.view_node.substr(1)}`;
      const metaTitle = this.articleData?.field_meta_title;
      const metaImg = this.getImgSrc(this.articleData?.field_image);
      const title = metaTitle === '' ? articleTitle : metaTitle;
      const createdDate = this.dateFormater(this.articleData.created);
      const baseurl =
        this.$i18n.locale !== 'ar'
          ? 'https://almarwan.com'
          : 'https://almarwan.com/ar';

      const meta = [];
      const script = [];
      const link = [];

      link.push({
        rel: 'canonical',
        href: this.getFullUrl(this.$route.fullPath)?.replace(/\/$/, ''),
      });

      meta.push(
        {
          hid: 'title',
          name: 'title',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          property: 'twitter:text:title',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'url',
          name: 'url',
          content: articleURL ? articleURL : baseurl,
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: articleURL ? articleURL : baseurl,
        },
        {
          hid: 'description',
          name: 'description',
          content: metaDescription,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: metaDescription,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: metaImg,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: metaImg,
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: metaTitle === '' ? articleTitle : metaTitle,
        },
        {
          hid: 'og:apple-mobile-web-app-title',
          name: 'og:apple-mobile-web-app-title',
          content: metaTitle === '' ? articleTitle : metaTitle,
        }
      );
      script.push({
        type: 'application/ld+json',
        class: 'saswp-schema-markup-output',
        json: [
          {
            '@context': 'https://schema.org/',
            '@type': 'Article',
            '@id': articleURL + '#Article',
            name: articleTitle,
            url: articleURL,
            inLanguage: 'en-US',
            mainEntityOfPage: articleURL,
            headline: articleTitle,
            description: metaDescription,
            articleBody: this.stripHtmlAndNewlines(this.articleData.body),
            image: metaImg,
            datePublished: createdDate + 'T04:00:00+04:00',
            author: {
              '@type': 'Person',
              name: this.articleData.author,
              url: `${process.env.BASE_URL}about`,
              image: {
                '@type': 'ImageObject',
                url: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
                height: '96',
                width: '96',
              },
            },
            publisher: {
              '@type': 'Organization',
              name: 'Al Marwan Heavy Machinery',
              url: articleURL,
              logo: {
                '@type': 'ImageObject',
                url: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
                width: '60',
                height: '60',
              },
            },
          },
        ],
      });

      return {
        title,
        meta,
        script,
        link,
      };
    }
  },
});
