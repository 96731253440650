var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news news__single"},[_c('div',{staticClass:"container md:block sm:hidden"},[(!_vm.blogLoading)?_c('custom-breadcrumbs',{staticClass:"ltr:ml-11 rtl:mr-11 mb-5",attrs:{"breadcrumbs":_vm.breadcrumbs}}):_c('SkeletonLoader',{attrs:{"width":"50%","height":"40px"}})],1),_vm._v(" "),_c('div',{staticClass:"news__container"},[_c('div',{staticClass:"news__content"},[(_vm.blogLoading)?_c('div',{staticClass:"news__box-meta"},[_c('SkeletonLoader',{attrs:{"height":"40px","width":"60%"}}),_vm._v(" "),_c('SkeletonLoader',{attrs:{"height":"70px","width":"80%"}}),_vm._v(" "),_c('SkeletonLoader',{attrs:{"height":"250px","width":"100%"}})],1):_vm._e(),_vm._v(" "),(_vm.articleData !== null && _vm.blogLoading === false)?_c('div',[_c('h1',[_vm._v(_vm._s(_vm.articleData.title))]),_vm._v(" "),_c('div',{staticClass:"news__box-meta"},[_c('span',[_c('nuxt-img',{staticClass:"calender-img",attrs:{"src":"/global/calender.svg","alt":"calender icon","width":"20","height":"20"}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.articleData.created))])],1),_vm._v(" "),_c('span',[_c('nuxt-img',{staticClass:"calender-img",attrs:{"src":"/global/User-Line.svg","alt":"user icon","width":"20","height":"20"}}),_vm._v(" "),_c('div',[_c('span',{staticClass:"inline-block"},[_vm._v(_vm._s(_vm.$t('By'))+" ")]),_vm._v(" "),_c('strong',{staticClass:"author inline-block"},[_vm._v(_vm._s(_vm.articleData.author))])])],1),_vm._v(" "),_c('social-label',{attrs:{"prodName":_vm.articleData.title,"url":'https://almarwan.com/news/' +
              _vm.articleData.nid +
              '/' +
              _vm.articleData.view_node.substr(1),"description":_vm.articleData.title}})],1),_vm._v(" "),(_vm.articleData.field_image !== '')?_c('div',{staticClass:"single-image"},[_c('nuxt-img',{staticClass:"article-image",attrs:{"src":_vm.getImgSrc(_vm.articleData.field_image),"alt":_vm.articleData.title,"format":"webp","preload":"","width":"665","height":"445"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content cms-content",domProps:{"innerHTML":_vm._s(_vm.articleData.body)}}),_vm._v(" "),_c('div',{staticClass:"single-page__tags"},_vm._l((_vm.fieldTags),function(tagItem,index){return _c('span',{key:index,staticClass:"single-page__tag active"},[_vm._v("\n            "+_vm._s(tagItem)+"\n          ")])}),0),_vm._v(" "),_c('div',{staticClass:"single-page__meta"},[_c('div',{staticClass:"single-page__share"},[_c('div',{staticClass:"copy-link",on:{"click":_vm.copyUrlToClipboard}},[_c('nuxt-img',{attrs:{"src":"/global/share-icon.svg","alt":"share icon","width":"20","height":"20"}}),_vm._v(" "),_c('span',[_vm._v("Share")])],1),_vm._v(" "),_c('ul',[_c('li',[_c('a',{attrs:{"target":"_blank","href":'https://api.whatsapp.com/send?text=' +
                    encodeURIComponent(
                      'https://almarwan.com/news/' +
                        _vm.articleData.nid +
                        '/' +
                        _vm.articleData.view_node.substr(1)
                    )}},[_c('nuxt-picture',{staticClass:"wa-icon",attrs:{"src":"/icons/whatsapp-black.svg","alt":"share on whatsapp","width":"20","height":"20"}})],1)]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":'https://twitter.com/share?url=' +
                    encodeURIComponent(
                      'https://almarwan.com/news/' +
                        _vm.articleData.nid +
                        '/' +
                        _vm.articleData.view_node.substr(1)
                    )}},[_c('Icon',{attrs:{"name":"twitter"}})],1)]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":'https://linkedin.com/shareArticle?url=' +
                    encodeURIComponent(
                      'https://almarwan.com/news/' +
                        _vm.articleData.nid +
                        '/' +
                        _vm.articleData.view_node.substr(1)
                    ),"target":"_blank"}},[_c('Icon',{attrs:{"name":"linkedIn"}})],1)])])])])]):_vm._e()]),_vm._v(" "),_c('aside',{staticClass:"page-sidebar-wrapper"},[_c('div',{staticClass:"page-sidebar"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('TopArticles')],1),_vm._v(" "),(_vm.imageGallery)?_c('Gallery',{attrs:{"image-gallery":_vm.imageGallery,"image-gallery-alt":_vm.imageGalleryAlt}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"subscribe-widget text--center"},[_c('nuxt-img',{staticStyle:{"margin-bottom":"20px"},attrs:{"src":"/global/subscribe-icon.svg","alt":"subscribe icon","width":"125","height":"125"}}),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.$t('Join Our Newsletter')))]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              'Be The First To Learn About Our Deals And Latest Industry News'
            ))+"\n        ")]),_vm._v(" "),_c('SubscribeWidget',{attrs:{"isFooter":true}})],1)])]),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('LatestNews',{attrs:{"latest-article-data":_vm.articleData,"pagination-vals":_vm.paginationVals}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('CustomerStories')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }